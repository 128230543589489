.scene-wrapper {
  .sidebar {
    max-width: 350px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-width: 300px;
    max-width: 300px;
    width: 70%;
    z-index: 9999;
    background-color: #76797a;
    transform: translateX(-100%);
    transition: all 0.3s ease-out;
    img {
      max-width: 100%;
    }
    .container {
      flex:1;
      display: flex;
      flex-direction: column;
      padding: 50px 0;
      padding-left: 20px;
      .content, .footer {
        flex: 1;
        height: 100%;
      }
      .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .logo {
          padding: 0;
          margin-bottom: 30px;
          pointer-events: none;
        }
        .menu {
          width: 100%;
          .submenu {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            .item {
              margin-bottom: 0;
              order: 2;
              transform: scale(0);
              display: none;
              &.toggle {
                order: 0;
                transform: scale(1);
                display: block;
              }
              &.active {
                order: 1;
                transform: scale(1);
                display: block;
              }
            }
            &.open {
              .item {
                display: block;
                transform: scale(1);
              }
            }
          }
          .item {
            position: relative;
            display: block;
            color: #76797a;
            background-color: #ffffff;
            padding: 15px 20px;
            padding-left: 40px;
            margin-bottom: 10px;
            width: 100%;
            text-align: left;
            cursor: pointer;
            p {
              margin-bottom: 0;
            }
            .svg-inline--fa {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 20px;
              color: #fd7527;
              font-size: 10px;
              opacity: 0;
              transition: all 0.1s ease-out;
            }
            &.toggle {
              .svg-inline--fa {
                opacity: 1 !important;
                font-size: 15px;
              }
            }
            &:hover, &.active {
              border-bottom-color: #76797a;
              .svg-inline--fa {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .footer {
      flex: 1;
      max-height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: 20px;
      margin-bottom: 20px;
      .item {
        margin-bottom: 15px;
        color: #76797a;
        background-color: #fafafa;
        padding: 15px;
        p {
          margin-bottom: 0;
          span {
            display: none;
          }
          .svg-inline--fa {
            font-size: 20px;
          }
        }
        &:hover, &.active {
          opacity: 0.8;
          border-bottom-color: #76797a;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  &.open {
    .sidebar {
      transform: translateX(0);
    }
  }
}

@media only screen and (max-device-width:768px) {
  .scene-wrapper {
    .sidebar {
      width: 70%;
      min-width: 70%;
      max-width: 70%;
    }
  }
}
