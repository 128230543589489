.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #76797a;
  .wrapper {
    .logo {
      display: block;
      pointer-events: none;
      width: 250px;
      height: auto;
      margin: 0 auto 30px;
    }
    form {
      width: 300px;
      background-color: #ffffff;
      padding: 30px;
    }
  }
}
