.ocr-input {
  position: relative;
  overflow: visible;
  .ocr-action {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    overflow: visible;
    span {
      font-size: 25px;
      color: #fd7527;
      cursor: pointer;
      transform: translateY(2.5px);
      &.ocr-tooltip {
        position: absolute !important;
        top: 0;
        right: 0;
        z-index: 2;
        color: #ffffff;
        font-size: 12px;
        width: 200px;
        transform: translateY(-115%) scale(0);
        transition: all 0.1s linear;
        background-color: #fd7527;
        padding: 5px;
        border-radius: 5px;
      }
    }
    &:hover {
      span {
        &.ocr-tooltip {
          transform: translateY(-115%) scale(1);
        }
      }
    }
  }
}
