body {
  font-family: 'Poppins', 'Montserrat', Helvetica, sans-serif;
}
.line-height{
  line-height: 35px;
  font-size: 20px;
}
.line-height-small{
  line-height: 25px;
}
.big{
  font-size: 40px
}
h1 {
  font-size: 96px;
}
h1, h2 {
  font-weight: 300;
  letter-spacing: -1.5px;
}
h2 {
  font-size: 48px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}
h3 {
  font-size: 26px;
}
h3, h4 {
  font-weight: 500;
}
h4 {
  font-size: 22px;
  letter-spacing: .25px
}
h5 {
  font-weight: 400;
  font-size: 18px;
}
h6 {
  font-weight: 500;
  font-size: 16px;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0px;
  line-height: 1.15em;
  margin-bottom: 15px;
}
p {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1.2em;
  margin-top: 0;
}
p, span {
  &.tag {
    display: inline-block;
    background-color: #fd7527;
    color: #ffffff;
    padding: 3px;
    margin-bottom: 0;
    letter-spacing: 0.4px;
    border-radius: 3px;
    &.secondary {
      background-color: #76797a;
    }
    &.success {
      background-color: #27ae60;
    }
    &.danger {
      background-color: #e74c3c;
    }
  }
}
p.small, input[type=text], input[type=email], input[type=password] {
  font-size: 14px;
  letter-spacing: 0.25px;
}
.caption {
  font-size: 12px;
  letter-spacing: 0.4px;
}
.overline {
  line-height: 1.5em;
  font-size: 12px;
  letter-spacing: 1.5px;
}
button, .btn, input[type=submit] {
  font-size: 14px;
  letter-spacing: 0.75px;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.uppercase {
  text-transform: uppercase !important;
}
a {
  transition: all 0.2s ease-in;
  cursor: pointer;
  text-decoration: none;
  color: #3498db;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.quote {
  font-style: italic;
  padding: 0 15px;
  h4 { margin-bottom: 5px; }
  h5 { color: #fd7527 }
}
@media only screen and (max-width:1199.98px) {
  h1, h2 {
    font-size: 28px;
    letter-spacing: normal;
  }
}
@media only screen and (max-width:480px) {
  h1, h2, h3, h4 {
    font-size: 24px;
    letter-spacing: .25px;
  }
  button, .btn, input[type=submit] {
    font-size: 12px;
    letter-spacing: 0.75px;
  }
}
@import url('https://fonts.googleapis.com/css?family=Montserrat:600,600i,700,700i&display=swap');
