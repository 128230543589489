html, body {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  #root {
    width: 100%;
    height: 100%;
  }
}
* {
  box-sizing: border-box;
}
img {
  max-width: 100%;
  &.ponter-disabled {
    pointer-events: none;
  }
}
.w-auto {
  width: auto !important;
}
@font-face {
   font-family: 'Poppins';
   src: url("fonts/poppins-regular.ttf") format("truetype");
}
@for $i from 0 through 50 {
  .p-#{$i} {
    padding: #{$i}px !important;
  }
  .mL-#{$i} {
    margin-left: #{$i}px !important;
  }
  .mR-#{$i} {
    margin-right: #{$i}px !important;
  }
  .mT-#{$i} {
    margin-top: #{$i}px !important;
  }
  .mB-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .mX-#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }
  .mY-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }
  .pL-#{$i} {
    padding-left: #{$i}px !important;
  }
  .pR-#{$i} {
    padding-right: #{$i}px !important;
  }
  .pT-#{$i} {
    padding-top: #{$i}px !important;
  }
  .pB-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .pX-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }
  .pY-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }
}

.divider {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(0,0,0,0.15);
}
