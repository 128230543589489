.grader-wrapper {
  margin-bottom: 10px;
  .grade-item {
    display: flex;
    h5 {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0;
      font-weight: 800;
    }
    label {
      flex: 1;
      text-transform: capitalize;
      margin-bottom: 0;
    }
    .grade-options {
      flex: 1;
      max-width: 110px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .option {
        width: 30px;
        height: 30px;
        border: none;
        color: #ffffff;
        margin-right: 5px;
        cursor: pointer;
        .svg-inline--fa {
          opacity: 0;
        }
        &:nth-child(3) {
          margin-right: 0;
        }
        &.active {
          .svg-inline--fa {
            opacity: 1;
          }
          &:nth-child(1) {
            background-color: #27ae60;
          }
          &:nth-child(2) {
            background-color: #e74c3c;
          }
          &:nth-child(3) {
            background-color: #f39c12;
          }
        }
      }
    }
  }
}
