table {
  width: 100%;
  thead {
    background-color: #dddddd;
    tr {
      th {
        padding: 5px 10px;
        border-bottom: 0.5px solid #eeeeee;
        .checkbox {
          margin: 0 auto;
        }
        .btn {
          &.sort {
            display: inline;
            background-color: rgba(0,0,0,0.2);
            padding: 2px 4px;
            height: auto;
            border-radius: 3px;
            .svg-inline--fa {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  tbody {
    tr {
      text-align: center;
      &:nth-child(odd) {
        td {
          background-color: #ffffff;
        }
      }
      &:nth-child(even) {
        td {
          background-color: #eeeeee;
        }
      }
      td {
        padding: 5px 10px;
        color: #76797a;
        form {
          .form-group {
            margin: 0;
            &.text-center {
              justify-content: center;
            }
          }
        }
        a {
          color: inherit;
          &:hover {
            color: #3498db;
          }
        }
        .btn {
          width: 100%;
        }
        .checkbox {
          margin: 0 auto;
        }
        &.action {
          padding: 5px 10px;
        }
        &.odd {
          background-color: #eeeeee;
        }
        &.even {
          background-color: #ffffff;
          min-width: 100px;
        }
      }
    }
  }
  &.bordered {
    border: 1px solid #eeeeee;
  }
  &.small {
    thead {
      tr {
        th {
          padding: 5px 10px;
        }
      }
    }
  }
  &.visible-md {
    display: none;
    @media screen and ($desktop) {
      display: table;
    }
  }
}

.table-responsive {
  display: block;
  @media screen and ($desktop) {
    display: none;
  }
}
