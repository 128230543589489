.autocomplete-input {
  position: relative;
  .dropdown {
    position: absolute;
    top: 37px;
    left: 0;
    right: 0;
    border: 1px solid #ddd;
    border-top: none;
    max-height: 30vh;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #ffffff;
    z-index: 5;
    .item {
      border-bottom: 1px solid #ddd;
      padding: 15px;
      margin-right: 0;
      cursor: pointer;
      text-align: center;
      transition: all 0.2s ease;
      p {
        margin-bottom: 0;
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: #eee;
      }
    }
  }
}
