.pdf-generator {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 50px;
  display: none;
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: -1;
  }
  .pdf-generator-container {
    z-index: 1;
    background-color: #ffffff;
    height: 100%;
    max-width: 960px;
    margin: 0 auto;
    overflow-y: scroll;
    .pdf-generator-header {
      padding: 25px 50px;
      border-bottom: 1px solid #e7e7e7;
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: #ffffff;
    }
    .pdf-generator-document {
      padding: 50px;
    }
  }
  &.active {
    display: block;
  }
}
