.flexbox {
  display: flex;
  .flex {
    flex: 1
  }
  &.f-row {
    flex-direction: row;
  }
  &.f-column {
    flex-direction: row;
  }
  &.f-wrap {
    flex-wrap: wrap;
  }
  &.align-center {
    align-items: center;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.col-2 {
    .flex {
      max-width: 50%;
      min-width: 50%;
    }
  }
  &.col-3 {
    .flex {
      max-width: 33.333333%;
      min-width: 33.333333%;
    }
  }
  &.col-4 {
    .flex {
      max-width: 25%;
      min-width: 25%;
    }
  }
  &.col-5 {
    .flex {
      max-width: 20%;
      min-width: 20%;
    }
  }
  &.col-6 {
    .flex {
      max-width: 16.666666%;
      min-width: 16.666666%;
    }
  }
  &.col-7 {
    .flex {
      max-width: 14.285714%;
      min-width: 14.285714%;
    }
  }
  &.col-8 {
    .flex {
      max-width: 12.5%;
      min-width: 12.5%;
    }
  }
  &.col-9 {
    .flex {
      max-width: 11.111111%;
      min-width: 11.111111%;
    }
  }
  &.col-10 {
    .flex {
      max-width: 10%;
      min-width: 10%;
    }
  }
  &.col-11 {
    .flex {
      max-width: 9.090909%;
      min-width: 9.090909%;
    }
  }
  &.col-12 {
    .flex {
      max-width: 8.333333%;
      min-width: 8.333333%;
    }
  }
}
