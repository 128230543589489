.scene-wrapper {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  background-color: #76797a;
  .scene, .sidebar {
    flex: 1;
  }
  .scene {
    padding: 0 50px 30px;
    height: 100%;
    overflow-y: scroll;
    background-color: #ffffff;
    color: #76797a;
    transition: all 0.3s ease-out;
    .header {
      position: sticky;
      top: 0;
      padding: 20px 0;
      display: flex;
      align-items: flex-end;
      background-color: #ffffff;
      margin-bottom: 25px;
      z-index: 10;
      @media screen and ($tablet) {
        align-items: flex-end;
      }
      .toggle, .title {
        flex: 1;
        width: 100%;
      }
      .toggle {
        height: 40px;
        max-width: 40px;
        margin-right: 10px;
      }
      .title {
        text-align: right;
        margin: 0 0 10px;
        line-height: 1;
        .subtitle {
          font-size: 70%;
          // color: #5f9ea0;
          @media screen and ($desktop) {
            font-size: 55%;
          }
        }
        @media screen and ($tablet) {
          padding-left: 30px;
          margin-bottom: 0;
        }
      }
      .title-w-search {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        padding-left: 10px;
        @media screen and ($tablet) {
          padding-left: 30px;
          align-items: flex-end;
          flex-wrap: wrap;
        }
        .searchbar {
          position: relative;
          width: 100%;
          @media screen and ($tablet) {
            flex: 1;
          }
          input {
            height: 40px;
            padding-right: 50px;
          }
          .btn {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            height: 40px;
            width: 40px;
            .svg-inline--fa {
              margin-right: 0;
            }
          }
        }
        .title {
          flex: 1;
        }
      }
      &.top {
        border-bottom: 1px solid #d7d7d7;
        .toggle {
          .svg-inline--fa {
            margin-right: 0;
          }
        }
      }
    }
    .filters {
      display: flex;
      flex-wrap: wrap;
      row-gap: 1rem;
      margin-bottom: 1rem;
      .btn-create {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-right: 0;
        .btn {
          width: 50%;
          @media screen and ($tablet) {
            width: auto;
          }
        }
        .divider {
          display: block;
          margin: 0.8rem 0 0;
          width: 100%;
          height: 1px;
          background: rgba(0,0,0,0.15);
          @media screen and ($tablet) {
            margin: 0 0.8rem;
            width: 1px;
            height: 1rem;
          }
        }
        @media screen and ($tablet) {
          flex-wrap: nowrap;
          width: auto;
        }
        @media screen and ($desktop) {
          display: none;
        }
      }
      @media screen and ($tablet) {
        flex-wrap: nowrap;
      }
      > * {
        margin-right: 5px;
      }
      .select-wrapper {
        min-width: 120px;
        height: 40px;
        flex: 1;
        select {
          height: 40px;
        }
      }
      input {
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #eeeeee;
        &:focus {
          outline: none;
        }
      }
    }
    .pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 1rem;
      .pages {
        margin: 0 1rem;
      }
    }
    .subheader {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media screen and ($desktop) {
        justify-content: flex-start;
      }
    }
    .container {
      width: 100%;
      margin: 0 auto;
    }
  }
  &.open {
    overflow: hidden;
    .scene {
      transform: translateX(300px);
    }
  }
}
@media only screen and (max-device-width:768px) {
  .scene-wrapper {
    display: block;
    padding: 10px;
    .scene {
      height: auto;
      padding: 30px;
      transition: all 0.3s ease-out;
      .header {
        .toggle {
          // display: block;
        }
      }
      .container {
        width: 100%;
      }
    }
    &.open {
      overflow: hidden;
      .scene {
        transform: translateX(70%);
      }
    }
  }
}
