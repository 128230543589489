.pdf-preview-box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.9);
  z-index: 12;
  transform: scale(0);
  text-align: center;
  pointer-events: none;
  &.open {
    transform: scale(1);
    pointer-events: visible;
  }
  .container {
    position: relative;
    top: 2%;
    // transform: translateY(-50%);
    background-color: #ffffff;
    max-width: 900px;
    height: 96%;
    // padding: 20px;
    border-radius: 5px;
    .toggle {
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 5px;
      background: none;
      border: none;
      color: #fd7527;
      font-size: 20px;
    }

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
    // button {
    //   display: block;
    //   margin: 0 auto 15px;
    //   &.input {
    //     margin-right: 0 !important;
    //   }
    // }
}
