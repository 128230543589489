.filters {
  .form-group {
    .item {
      margin-right: 0;
      padding-right: 0;
      &.action {
        padding-right: 15px;
        max-width: 150px;
        .btn {
          width: 100%;
        }
      }
    }
  }
}
