form {
  label {
    display: block;
    margin-bottom: 10px;
  }
  input, select, textarea {
    border: none;
    background-color: rgba(0,0,0,0.1);
    padding: 10px;
    font-size: 16px;
    letter-spacing: 0.5px;
    width: 100%;
    outline: none;
    margin: 0;
    color: inherit;
    border-radius: 0;
    min-height: 40px;
    &.error {
      border: 2px solid #e74c3c;
    }
  }
  .files {
    margin: 0 -5px;
    .file {
      text-align: center;
      background-color: rgba(0,0,0,0.1);
      padding: 15px;
      margin: 5px;
      > img {
        max-width: 234px;
        margin-bottom: 5px;
        width: 100%;
      }
      > .svg-inline--fa {
        margin-bottom: 5px;
        font-size: 50px;
      }
    }
  }
  .file-input {
    flex: 1;
    position: relative;
    border: none;
    background-color: #3498db;
    padding: 10px;
    font-size: 16px;
    letter-spacing: 0.5px;
    width: 100%;
    height: 40px;
    outline: none;
    margin: 0;
    color: inherit;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    overflow: hidden;
    &.small {
      max-width: 40px;
      width: 40px;
      .svg-inline--fa {
        margin-right: 0;
      }
    }
    p {
      margin-bottom: 0;
      margin-right: 10px;
    }
    input {
      cursor: pointer;
      opacity: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      z-index: 1;
      width: 100%;
    }
  }
  table {
    input, select, textarea {
      background-color: rgba(0,0,0,0.03);
      text-align: center;
    }
  }
  .select-wrapper {
    position: relative;
    height: 40px;
    background-color: rgba(0,0,0,0.1);
    select {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: transparent;
      z-index: 3;
    }
    .svg-inline--fa {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      font-size: 20px;
    }
    &.error {
      border: 2px solid #e74c3c;
    }
  }
  .action-input {
    position: relative;
    padding-right: 35px;
    .action {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      .btn {
        background-color: rgba(0,0,0,0.15);
        height: 100%;
        width: 35px;
        padding: 0;
        text-align: center;
        .svg-inline--fa {
          color: #76797a;
          margin-right: 0;
        }
        &.success {
          .svg-inline--fa {
            &.fa-plus-circle {
              color: #27ae60;
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }
  select {
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  textarea {
    resize: none;
  }
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      input::placeholder {
        opacity: 0.5;
      }
    }
  }
  .react-datepicker__tab-loop {
    z-index: 99999;
    .react-datepicker-popper {
      z-index: 99999;
    }
  }
  .checkgroup {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: -15px;
    &--capitalize {
      text-transform: capitalize;
    }
    .button {
      background-color: transparent;
      margin-right: 10px;
      border-width: 2px;
      margin-bottom: 15px;
      padding: 5px;
      border-color: #3498db;
      cursor: pointer;
      .svg-inline--fa {
        display: inline;
        margin-right: 5px;
        &.checked {
          display: none;
        }
      }
      &.active {
        color: #ffffff;
        background-color: #3498db;
        .svg-inline--fa {
          display: none;
          &.checked {
            display: inline;
          }
        }
      }
    }
    &--justified {
      .button {
        position: relative;
        flex-basis: 30%;
        padding-left: 1.7rem;
        .svg-inline--fa {
          position: absolute;
          top: 0.55rem;
          left: 0.3rem;
        }
      }
    }
  }
  .form-group {
    display: flex;
    margin: 0 -15px 15px;
    .item {
      flex: 1;
      // margin-right: 15px;
      padding: 0 15px;
      &:last-child {
        margin-right: 0;
      }
      &.border-r {
        margin-right: 0;
        padding-right: 15px;
        border-right: 1px solid #d7d7d7;
      }
      &.border-t {
        border-top: 1px solid #d7d7d7;
      }
      &--bg-bordered {
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(0,0,0,0.03);
        // height: 50px;
        // overflow: auto;
      }
    }
    &.border-r {
      > .item {
        margin-right: 0;
        padding-right: 15px;
        border-right: 1px solid #d7d7d7;
        &:last-child {
          border-right: none;
          padding-left: 15px;
        }
      }
    }
  }
  &.export {
    .form-group {
      display: block;
      .item {
        flex: none;
        display: inline-block;
        width: 100%;
        margin-right: 0;
      }
      &.col-2 {
        > .item {
          width: 50%;
        }
      }
      &.col-3 {
        > .item {
          width: 33.333%;
        }
      }
    }
  }
}
@media only screen and (max-width:768px) {
  form {
    .form-group {
      flex-direction: column;
      .item {
        margin-right: 0;
        &.border-r {
          border-right: none !important;
        }
      }
      &.border-r {
        > .item {
          border-right: none;
        }
      }
    }
  }
}
