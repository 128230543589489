.ocr-box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.9);
  z-index: 12;
  transform: scale(0);
  text-align: center;
  &.open {
    transform: scale(1);
  }
  .container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ffffff;
    max-width: 350px;
    padding: 20px;
    border-radius: 5px;
    .toggle {
      position: absolute;
      top: 15px;
      right: 15px;
      background: none;
      border: none;
      color: #fd7527;
      font-size: 18px;
    }
    h2 {
      font-weight: 400;
      margin-bottom: 20px;
      font-size: 1.5em;
    }
    .preview {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
      }
    }
    button {
      display: block;
      margin: 0 auto 15px;
      &.input {
        margin-right: 0 !important;
      }
    }
    .footer {
      display: flex;
      align-items: center;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      background-color: rgba(0,0,0,0.5);
      z-index: 2;
      .toggle {
        flex: 1;
        display: flex;
        &:last-child {
          justify-content: flex-end;
        }
      }
      .btn {
        position: relative;
        width: 100px;
        .svg-inline--fa {
          margin-right: 0;
          font-size: 20px;
        }
      }
      .logo {
        height: 80px;
      }
    }
    .error {
      z-index: 2;
    }
    .react-html5-camera-photo-fullscreen {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .result {
      width: 100%;
      max-width: 620px;
      padding: 20px;
      margin: 0 auto;
      text-align: center;
      .btn {
        margin: 0 auto 15px;
        display: block;
        &:last-child {
          margin-bottom: 0;
        }
      }
      img {
        width: 100%;
        height: 20vh;
        object-fit: contain;
        pointer-events: none;
        margin-bottom: 15px;
        background-color: #eeeeee;

      }
    }
  }
  .btn {
    display: inline-flex;
    background-color:#fd7527;
    box-shadow: none;
    border: none;
    height: 50px;
    padding: 0 30px;
    color: #ffffff;
    transition: all 0.2s ease-in;
    margin: 0;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-bottom: 15px;
    border-radius: 7px;
    &.cancel {
      padding: 0 15px !important;
      background: none;
      color: #fd7527;
      height: auto;
      margin-bottom: 0;
    }
    .glyphicon {
      font-size: 18px;
      margin-right: 10px;
    }
    &.large {
      width: 100%;
    }
    &.input {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      input {
        opacity: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 1;
        width: 150px;
      }
    }
    &:hover {
      background-color: rgba(253, 117, 39, 0.8);
      color: #ffffff;
      &.success {
        background-color: rgba(39, 174, 96, 0.8);
      }
      &.reference {
        background-color: rgba(52, 152, 219, 0.8);
      }
      &.cancel {
        background-color: transparent;
        color:  rgba(253, 117, 39, 0.8);
      }
    }
  }
  .gly-spin {
    -webkit-animation: spin 2s infinite linear;
    -moz-animation: spin 2s infinite linear;
    -o-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
  }
  @-moz-keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(359deg);
    }
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
    }
  }
  @-o-keyframes spin {
    0% {
      -o-transform: rotate(0deg);
    }
    100% {
      -o-transform: rotate(359deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
}
