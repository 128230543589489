button {
  outline: none;
  border: none;
  box-shadow: none;
}
.btn {
  display: flex;
  background-color: #fd7527;
  height: 40px;
  padding: 0 15px;
  color: #ffffff;
  transition: all 0.2s ease-in;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  .svg-inline--fa {
    margin-right: 5px;
  }
  &.big {
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
  }
  &.small {
    max-width: 40px;
    width: 40px;
    .svg-inline--fa {
      margin-right: 0;
    }
  }
  &.large {
    width: 100%;
  }
  &.success {
    background-color: #27ae60;
  }
  &.reference {
    background-color: #3498db;
  }
  &.danger {
    background-color: #e74c3c;
  }
  &.block {
    width: 100%;
  }
  &.submit {
    max-width: 220px;
  }
  &:hover {
    background-color: rgba(253, 119, 39, 0.8);
    color: #ffffff;
    &.success {
      background-color: rgba(39, 174, 96, 0.8);
    }
    &.reference {
      background-color: rgba(52, 152, 219, 0.8);
    }
    &.warning {
      background-color: gold;
    }
  }
}
