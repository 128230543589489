.Collapsible-table {
  &__header {
    width: 100%;
    padding: 0.8rem 0.8rem;
    .id {
      margin-right: 0.7rem;
      padding: 0.1rem 0.2rem;
      background: #fd7527;
      color: #FFF;
      // border: 1px solid rgba(0,0,0,0.15);
      border-radius: 6px;
      font-weight: 700;
    }
    .name {
      font-weight: 600;
    }
    .vin {
      font-size: 0.9rem;
    }
  }
  &__list {
    margin: 0;
    list-style-type: none;
    padding-left: 0;
    li {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 0.3rem 0;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(0,0,0,0.15);
      }
      span {
        margin-right: 0.5rem;
        display: inline-block;
        width: 5rem;
        font-size: 0.9rem;
        font-weight: 600;
        letter-spacing: 1px;
        @media screen and ($tablet) {
          width: 8rem;
        }
      }
      div {
        text-align: right;
        flex: 1;
        @media screen and ($tablet) {
          text-align: left;
        }
      }
    }
    + .btn {
      @media screen and ($tablet) {
        width: 10rem;
        // flex: auto;
      }
    }
  }
}

// Overrides library styles
.Collapsible {
  // box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
  border: 1px solid rgba(0,0,0,0.15);
  margin-bottom: 0.4rem;
  &__contentInner {
    padding: 1rem;
    background-color: rgba(0,0,0,0.02);
  }
  &__trigger {
    display: block;
    transition: all 0.15s ease-out;
    &.is-open {
      border-bottom: 1px solid rgba(0,0,0,0.15);
      background: #3498db;
      color: #FFF;
    }
  }
}
