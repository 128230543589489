.valuation {
  position: relative;
  &.existing {
    .expandible {
      .header {
        position: sticky;
        top: 0;
        background-color: #ffffff;
        padding: 15px 0 !important;
        border-bottom: 1px solid #d7d7d7;
        z-index: 2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0;
        .title {
          text-align: left;
        }
        .toggle {
          flex: 1;
          max-width: 220px;
          display: flex;
          justify-content: flex-end;
          margin-right: 5px;
          &.only-open {
            display: none;
          }
          .btn {
            width: 100%;
            .collapsed {
              display: inline;
            }
            .showing {
              display: none;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .body {
        display: none;
        padding: 20px 20px 35px;
        border-bottom: 1px solid #d7d7d7;
      }
      &.active {
        .header {
          border-bottom: none;
          padding-bottom: 25px !important;
          .toggle {
            &.only-open {
              display: flex;
            }
            .btn {
              .collapsed {
                display: none;
              }
              .showing {
                display: inline;
              }
            }
          }
        }
        .body {
          display: block;
        }
      }
    }
  }
  .boxVimInfo1{
    width: 100%;
    background: #eee;
    border: 1px solid #ff5722;
  }
  .boxVimInfo1b{
    width: 100%;
    background: #eee;
    border: 1px solid #ff5722;
    margin-bottom: 20px;
  }
  .boxVimInfo2{
    width: 100%;
    background: #ff9800;
    color: #fff;
    padding: 5px 10px;
  }
  .boxVimInfo3{
    width: 100%;
    background: #eee;
    padding: 5px 10px;
  }
  .boxVimInfo4{
    width: 100%;
    font-size: 0.8em;
    border-bottom: 1px dotted #000000;
    padding-top: 5px;
  } 
  .boxVimInfo5{
    width: 50%;
    float: left;
  } 
  .boxVimInfo6{
    width: 50%;
    float: left;
    text-align: right;
  } 
  .boxVimInfo7{
    clear: both;
  }  
}
