.splash {
  width: 100;
  height: 100%;
  padding: 100px 0;
  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    .svg-inline--fa {
      font-size: 60px;
    }
  }
  &.empty {
    width: 100%;
    height: 100%;
    .container {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .content {
        .header {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        }
      }
    }
    .children {
      text-align: center;
      h6 {
        margin-top: 15px;
      }
      .svg-inline--fa {
        font-size: 100px;
      }
    }
  }
}
